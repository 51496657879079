/*Header */
// jQuery(document).ready(function($) {
//   // Cache the image element and the current image source
//   var $changeImage = $('#changeimage');
//   var currentImageSrc = '';
//
//   // Handle hover on menu items
//   $('.headermenu li').hover(function() {
//     var dataImage = $(this).data('image');
//     if (dataImage !== currentImageSrc) {
//       $changeImage.attr('src', dataImage);
//       currentImageSrc = dataImage;
//     }
//   }, function() {
//     // Clear the image source when the mouse leaves the menu item
//     if (currentImageSrc !== '') {
//       $changeImage.attr('src', currentImageSrc);
//     }
//   });
//
//   // Initialize variables for animation
//   var mouseX = 0;
//   var mouseY = 0;
//   var imageX = 0;
//   var imageY = 0;
//   var imageWidth = $changeImage.width();
//   var imageHeight = $changeImage.height();
//   // Function to update mouse position
//   function updateMousePosition(e) {
//     mouseX = e.clientX;
//     mouseY = e.clientY;
//   }
//
//   // Function to smoothly move the image
//   function moveImage() {
//     var dx = (mouseX - imageX) * 0.05 - imageWidth/2;
//     var dy = (mouseY - imageY) * 0.05 - imageHeight/2;
//
//     imageX += dx;
//     imageY += dy;
//
//     $changeImage.css({
//       transform: `translate(${imageX}px, ${imageY}px)`,
//     });
//
//     requestAnimationFrame(moveImage);
//   }
//
//   // Attach mousemove event listener to update the mouse position
//   $(document).on('mousemove', updateMousePosition);
//
//   // Start the animation
//   requestAnimationFrame(moveImage);
// });

/**/

// jQuery(document).ready(function($) {
// $( "#menu-main-menu li").each(function( index ) {
//     var img_src=$(this).find('a').find('img').attr('src');
//     if(index==1){
//       $('#changeimage').attr('src',img_src);
//     }
// });
// });
// $( "#menu-main-menu li").each(function( index ) {
//       var img_src=$(this).find('a').find('img').attr('src');
//       $(this).attr('data-image',img_src);
// });

/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 40) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});


/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_button").on('click', function() {

      if($(this).hasClass('is-active')){
          //$('#changeimage').css('opacity','0');
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          $('.headermenu').removeClass('is-active');
          $('.header_wrapper').removeClass('openmenu');
          //$('.menu li').removeClass('aos-animate');
        }
        else{
        //  $('#changeimage').css('opacity','1');
          $(this).addClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
          $('.header_wrapper').addClass('openmenu');
      }


});

if (window.innerWidth < 768) {
  document.querySelectorAll('#home-services .service').forEach((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle('active');
    })
  })
}

const allPartners = document.querySelectorAll('.partner')
// filter partners that have a child element with class .partner-image
const partners = Array.from(allPartners).filter(el => el.querySelector('.partner-img'))
var winWidth = window.innerWidth;

const enablePartnersGSAP = () => {
  if(winWidth < 789){
    console.log('mobile view');
  }
    else
  {
    partners.forEach(addMouseEnter)
    partners.forEach(addMouseLeave)
    partners.forEach(addMouseMove)
  }
}

const disablePartnersGSAP = () => {
  partners.forEach(removeMouseEvents)
}

const addMouseEnter = (el) => {
  el.addEventListener('mouseenter', enterHandler)
}

const addMouseLeave = (el) => {
  el.addEventListener('mouseleave', leaveHandler)
}

const addMouseMove = (el) => {
  el.addEventListener('mousemove', moveHandler)
}

const removeMouseEvents = (el) => {
  el.removeEventListener('mouseenter', enterHandler)
  el.removeEventListener('mouseleave', leaveHandler)
  el.removeEventListener('mousemove', moveHandler)
}

const enterHandler = (e) => {
  const image = e.currentTarget.querySelector('.partner-img img')
  gsap.to(image, { autoAlpha: 1 });
}

const leaveHandler = (e) => {
  const image = e.currentTarget.querySelector('.partner-img img')
  gsap.to(image, { autoAlpha: 0 });
}

const moveHandler = (e) => {
  const image = e.currentTarget.querySelector('.partner-img img')
  const rect = image.getBoundingClientRect();
  const imageCenterX = rect.left + rect.width / 2;
  const imageCenterY = rect.top -30;
  gsap.to(image,{
      x: e.clientX - imageCenterX,
      y: e.clientY - imageCenterY,
      duration: 1,
      ease: 'power2.out',
  })
}

enablePartnersGSAP();

allPartners.forEach((partner) => {
  const dialog = partner.querySelector(".partner-dialog");
  const showButton = partner.querySelector(".partner-open-dialog");
  const closeButton = partner.querySelector(".partner-close-dialog");
  
  // "Show the dialog" button opens the dialog modally
  showButton.addEventListener("click", () => {
    dialog.showModal();
    //check if partner is in partners
    if (partner.querySelector('.partner-img')) {
      partner.querySelector('.partner-img img').style.opacity = 0;
      disablePartnersGSAP();
    }
  });
  
  // "Close" button closes the dialog
  closeButton.addEventListener("click", () => {
    dialog.close();
    if (partner.querySelector('.partner-img')) {
      enablePartnersGSAP();
    }
  });
})

import Swiper, { Navigation } from 'swiper';
//document.addEventListener("DOMContentLoaded", function() {
const servicesSwiper = new Swiper(".services-swiper", {
  slidesPerView: 1,
  breakpoints: {
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
  },
  pagination: {
    el: ".services-swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  navigation: {
    prevEl: '.services-slider-prev',
    nextEl: '.services-slider-next',
  },
});

const servicesContent = document.querySelectorAll('#services .service .service-right')

servicesContent.forEach((el) => {
  if (el.offsetHeight == 600) {
    el.classList.add('expandable-service');
    el.querySelector('.service-expand').addEventListener('click', () => {
      console.log(el);
      if (!el.classList.contains('active')){
        el.style.maxHeight = el.scrollHeight + 'px';
        el.classList.add('active');
      } else {
        el.style.maxHeight = '600px';
        el.classList.remove('active');
      }
    })
  }
})